import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ITCBold } from '@styles/Typography'

export const ProjectStats = styled.section`
  ${box({
    spacing: 'horizontal',
  })};
  padding-top: ${spacing(5)};
  background: ${tokens.brand('light')};

  .inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${spacing(6)};
    grid-row-gap: ${spacing(3)};
    margin: 0 auto;
    max-width: ${tokens.get('maxWidth.vastSmall')};
    width: 100%;

    ${media.greaterThan('m')`
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `}

    ${media.greaterThan('xl')`
      max-width: ${tokens.get('maxWidth.vast')};
    `}
  }
`

export const ProjectStat = styled.div`
  flex: 0 1 auto;

  &:last-child {
    margin-right: 0;
  }

  ${media.greaterThan('m')`
    margin-right: ${spacing(6)};
  `}

  ${media.greaterThan('l')`
    margin-right: ${spacing(10)};
  `}

  .label {
    ${ITCBold};
    color: ${tokens.brand('dark')};
    font-size: 0.75rem;
    letter-spacing: ${tokens.get('type.letterSpacing.medium')};
    text-transform: uppercase;
    margin-bottom: ${spacing()};

    ${media.greaterThan('m')`
      font-size: 0.875rem;
    `}
  }

  .content {
    ${ITCBold};
    color: ${tokens.brand('dark')};
    font-size: 1rem;
    line-height: 1.25;

    ${media.greaterThan('m')`
      font-size: 1.125rem;
    `}
  }

  .platform-mask {
    max-height: 1.25em;

    svg {
      margin-top: -0.15em;
      fill: ${tokens.brand('dark')};

      .alt-color {
        fill: ${tokens.brand('light')};
      }
    }
  }
`
