import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import PageComponents from '@components/PageComponents'
import Footer from '@components/Footer'
import * as S from '@styles/components/Project'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'
import { PlatformList } from '@partials/PlatformList'

export const ProjectTemplate = ({
  hero,
  components,
  client,
  role,
  deliverables,
  platform,
  slug,
  project,
}) => {
  const PlatformSvg = PlatformList[platform.slug]

  return (
    <Container className={`project project--${slug}`} page={project}>
      <Hero {...hero} logo={client.acf.logo} />
      <main>
        <article>
          <S.ProjectStats>
            <div className="inner">
              {client && (
                <S.ProjectStat className="client">
                  <div className="label">Client</div>
                  {/* TODO: Link to client page? */}
                  <h4 className="content">{client.post_title}</h4>
                </S.ProjectStat>
              )}
              {role && (
                <S.ProjectStat className="role">
                  <div className="label">Our Role</div>
                  <h5 className="content">{role}</h5>
                </S.ProjectStat>
              )}
              {deliverables && (
                <S.ProjectStat className="deliverables">
                  <div className="label">Deliverables</div>
                  <h5 className="content">{deliverables}</h5>
                </S.ProjectStat>
              )}
              {platform && (
                <S.ProjectStat className="platform">
                  <div className="label">Platform</div>
                  {/* TODO: Link to platform page */}
                  {PlatformSvg ? (
                    <div className="platform-mask">
                      <PlatformSvg />
                    </div>
                  ) : (
                    <h5 className="content">{platform.name}</h5>
                  )}
                </S.ProjectStat>
              )}
            </div>
          </S.ProjectStats>
          <PageComponents components={components} />
        </article>
      </main>
      <Footer />
    </Container>
  )
}

ProjectTemplate.propTypes = {
  hero: PropTypes.object,
  title: PropTypes.string,
  role: PropTypes.string,
  deliverables: PropTypes.string,
  platform: PropTypes.object,
  client: PropTypes.object,
  components: PropTypes.array,
}

const Project = ({ data }) => {
  const {
    wordpressWpProject: { yoast_meta: meta, ...project },
  } = data

  const hero = {
    heading: project.title,
    type: 'banner',
    image: project.acf.hero_image,
    client: project.acf.client,
  }

  project.designations = ['project']

  return (
    <>
      <SEO {...meta} path={project.path} />
      <ProjectTemplate
        hero={hero}
        title={project.title}
        role={project.acf.role}
        deliverables={project.acf.deliverables}
        platform={project.acf.platform}
        client={project.acf.client}
        components={project.acf.content_project}
        project={project}
      />
    </>
  )
}

Project.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Project

export const pageQuery = graphql`
  #TODO: Use
  fragment ProjectFields on wordpress__wp_project {
    id
    title
    slug
    path
    wordpress_id
    acf {
      role
      deliverables
      platform {
        name
        slug
      }
      client {
        post_title
      }
      hero_image {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      featured_images {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      content_project {
        __typename
        ...AccordionBlockComponentFragment
        ...BannerComponentFragment
        ...CardsComponentFragment
        ...FeaturedProjectsComponentFragment
        ...FormComponentFragment
        ...ImageComponentFragment
        ...ImageBlocksComponentFragment
        ...LogoGridComponentFragment
        ...ListBlockComponentFragment
        ...SliderComponentFragment
        ...TeamComponentFragment
        ...TestimonialsComponentFragment
        ...TextComponentFragment
        ...TextWithImagesFragment
        ...TilesComponentFragment
      }
    }
    yoast_meta {
      ...metaData
    }
  }
  query ProjectByID($id: String!) {
    wordpressWpProject(id: { eq: $id }) {
      id
      title
      slug
      path
      wordpress_id
      designations
      acf {
        role
        deliverables
        platform {
          name
          slug
        }
        client {
          post_title
          acf {
            logo {
              image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
        hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        featured_images {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        content_project {
          __typename
          ...AccordionBlockComponentFragment
          ...BannerComponentFragment
          ...CardsComponentFragment
          ...FeaturedProjectsComponentFragment
          ...FormComponentFragment
          ...ImageComponentFragment
          ...ImageBlocksComponentFragment
          ...LogoGridComponentFragment
          ...ListBlockComponentFragment
          ...SliderComponentFragment
          ...TeamComponentFragment
          ...TestimonialsComponentFragment
          ...TextComponentFragment
          ...TextWithImagesFragment
          ...TilesComponentFragment
        }
      }
      yoast_meta {
        ...metaData
      }
    }
  }
`
